<template>
  <router-link :to="{path: `/sgl-events/${getTournamentGroupId}`}">
    <div class="discipline-cell">
      <img class="discipline-cell--bg" :src="getPreview" alt="#">
      <div class="discipline-cell--content">
        <div class="discipline-cell--chip">
          {{ getCountOfTournaments }}
          <media :query="{minWidth:526}">
            {{ getParsedText }}
          </media>
        </div>
        <div class="discipline-cell--logo">
          <img :src="getLogo" alt="Not Found"/>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Media from 'vue-media';

export default {
  name: "tournamentGroup",
  props: {
    group: Object
  },
  components: {Media},
  computed: {
    getCountOfTournaments() {
      return this.group.attributes['tournaments_count'];
    },

    getTournamentGroupId() {
      return this.group.id
    },

    getPreview() {
      return this.group.preview
    },

    getLogo() {
      return this.group.logo
    },

    getParsedText() {
      if (this.getCountOfTournaments === 1) {
        return 'Турнир'
      } else if (
          this.getCountOfTournaments >= 2
          && this.getCountOfTournaments <= 4
      ) {
        return 'Турнира'
      } else return 'Турниров'
    }
  },
}
</script>

<style scoped>

</style>