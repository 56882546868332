<template>
  <div class="sgl-main">
<!--    <img class="bg-stamp" :src="require('@/img/pattern.png')" alt="#">-->

    <section class="hero-section">
      <main-page-banner/>
    </section>

    <section class="content-section" v-if="isHaveTournamentGroups">
      <tournament-groups/>
    </section>

    <section class="content-section" v-if="!isHaveNotUpcomingTournaments">
      <upcoming-tournaments/>
    </section>

    <section class="content-section" v-if="GET_SPONSOR_BANNERS_FOR_MIDDLE_MAIN">
      <div class="inner-container">
        <banner-component :images="GET_SPONSOR_BANNERS_FOR_MIDDLE_MAIN" :link="sponsor.data.attributes.main_middle_banner_link"/>
      </div>
    </section>

    <active-stream title="Онлайн трансляция"/>

    <!--    <section v-if="isHaveTournamentGroupsAndSponsorGames"-->
    <!--             class="content-section content-section&#45;&#45;gradient content-section&#45;&#45;p100">-->
    <!--      <rating-of-participants/>-->
    <!--    </section>-->

    <!--    <media :query="{minWidth:526}">
          <section class="content-section">
            <div class="inner-container">
              <div class="section-head">
                <h2 class="section-title">Новости</h2>
                <div class="ellipse-button">
                  смотреть все
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              <slither-slider class="news-slider" :options="{
                controls: false,
                numberOfSlides: [
                { number: 2, min: 0 },
                { number: 3, min: 769 }],
                animationEasing: 'easeInOutQuint',
                animationDuration: 650,
                gap: 25,
            }">
                <div v-for="s in 9" :key="s" class="single-slide">
                  <img class="single-slide&#45;&#45;bg" :src="require('@/img/news-slide-bg.png')" alt="#">
                  <div class="single-slide&#45;&#45;content">
                    <div class="single-slide&#45;&#45;head">
                      <div class="sgl-label sgl-label&#45;&#45;colored">новости</div>
                      <div class="sgl-label">Вчера</div>
                    </div>
                    <div class="single-slide&#45;&#45;title">«У VP в матче c NoTechies не возникнет проблем» — прогнозы
                      Cybersport.ru
                      и «Рейтинга Букмекеров»
                    </div>
                  </div>
                </div>
              </slither-slider>
            </div>
          </section>
        </media>
        <media :query="{maxWidth:525}">
          <section class="content-section">
            <div class="inner-container">
              <div class="section-head">
                <h2 class="section-title">Новости</h2>
              </div>
              <div class="sgl-mobile-news">
                <div v-for="s in 3" :key="s" class="single-slide">
                  <img class="single-slide&#45;&#45;bg" :src="require('@/img/news-slide-bg.png')" alt="#">
                  <div class="single-slide&#45;&#45;content">
                    <div class="single-slide&#45;&#45;head">
                      <div class="sgl-label sgl-label&#45;&#45;colored">новости</div>
                      <div class="sgl-label">Вчера</div>
                    </div>
                    <div class="single-slide&#45;&#45;title">«У VP в матче c NoTechies не возникнет проблем» — прогнозы
                      Cybersport.ru
                      и «Рейтинга Букмекеров»
                    </div>
                  </div>
                </div>
              </div>
              <media :query="{maxWidth:525}">
                <div class="ellipse-button">
                  смотреть все
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
                  </svg>
                </div>
              </media>
            </div>
          </section>
        </media>-->
    <section class="content-section" v-if="GET_SPONSOR_BANNERS_FOR_BOTTOM_MAIN">
      <div class="inner-container">
        <banner-component :images="GET_SPONSOR_BANNERS_FOR_BOTTOM_MAIN" :link="sponsor.data.attributes.main_bottom_banner_link" position="bottom"/>
      </div>
    </section>

    <news-slider/>
    <!--    <section class="content-section content-section&#45;&#45;gradient content-section&#45;&#45;p100 content-section&#45;&#45;mb0">
          <div class="inner-container">
            <ul class="sgl-sponsors-list">
              <li><img :src="require('@/img/partners-logo/apple.png')" alt="#"></li>
              <li><img :src="require('@/img/partners-logo/BMW_logo_(gray) 1.png')" alt="#"></li>
              <li><img :src="require('@/img/partners-logo/intel-header-logo 1.png')" alt="#"></li>
              <li><img :src="require('@/img/partners-logo/Mastercard_2019_logo 1.png')" alt="#"></li>
              <li><img :src="require('@/img/partners-logo/razer_logo.png')" alt="#"></li>
              <li><img :src="require('@/img/partners-logo/ROG.png')" alt="#"></li>
              <li><img :src="require('@/img/partners-logo/valve.png')" alt="#"></li>
              <li><img :src="require('@/img/partners-logo/Yandex_logo_en 1.png')" alt="#"></li>
            </ul>
          </div>
        </section>-->
    <scroll-top/>
  </div>
</template>

<script>
import TournamentGroups from "@/components/tournamentGroups/tournamentGroups";
import {mapActions, mapGetters, mapState} from "vuex";
import UpcomingTournaments from "@/components/upcomingTournaments/upcomingTournaments";
import RatingOfParticipants from "@/components/ratingOfParticipants/ratingOfParticipants";
import BannerComponent from "@/components/bannerComponent/bannerComponent";
import ScrollTop from "@/components/UIElements/scrollTop";
import ActiveStream from "@/components/streams/activeStream/active-stream";
import MainPageBanner from "@/components/home/mainPageBanner";
import NewsSlider from "@/components/News/news-slider";

export default {
  name: "sglMain",
  components: {
    NewsSlider,
    MainPageBanner,
    BannerComponent,
    // RatingOfParticipants,
    TournamentGroups,
    ActiveStream,
    ScrollTop,
    UpcomingTournaments
  },
  data() {
    return {
      scrollButtonVisible: false,
      tournamentsListVisible: false
    }
  },
  async created() {
    // Получаю список игр
    await this.GET_DISCIPLINES();

    // Получаю информацию о спонсоре
    await this.SET_SPONSOR_INFO('main');

    // Делаю запрос на сервер, чтобы получить группы турниров
    await this.SET_TOURNAMENT_GROUPS()

    // Задаю фильтр для ближайших турниров
    await this.SET_UPCOMING_TOURNAMENTS('All')

    window.addEventListener("scroll", this.throttle(this.displayScrollButton, 128));

  },
  destroyed() {
    window.removeEventListener("scroll", this.throttle(this.displayScrollButton, 128));
  },
  methods: {
    ...mapActions([
      'GET_DISCIPLINES',
      'SET_SPONSOR_INFO',
      'SET_SPONSOR_GAMES',
      'SET_TOURNAMENT_GROUPS',
      'SET_UPCOMING_TOURNAMENTS'
    ]),
    displayScrollButton() {
      this.scrollButtonVisible = window.scrollY >= window.innerHeight;
    },
    scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    throttle(func, ms) {
      let isThrottled = false,
          savedArgs,
          savedThis;

      function wrapper() {
        if (isThrottled) {
          savedArgs = arguments;
          savedThis = this;
          return;
        }
        func.apply(this, arguments);
        isThrottled = true;
        setTimeout(function () {
          isThrottled = false;
          if (savedArgs) {
            wrapper.apply(savedThis, savedArgs);
            savedArgs = savedThis = null;
          }
        }, ms);
      }

      return wrapper;
    },
    toggleList() {
      if (this.tournamentsListVisible) {
        this.$refs.listContainer.style.maxHeight = `0px`
        this.tournamentsListVisible = false
      } else {
        this.$refs.listContainer.style.maxHeight = `${this.$refs.listContainer.scrollHeight}px`
        this.tournamentsListVisible = true
      }
    }
  },
  computed: {
    ...mapGetters([
      'GET_TOURNAMENT_GROUPS',
      'GET_SPONSOR_BANNERS_FOR_MIDDLE_MAIN',
      'GET_SPONSOR_BANNERS_FOR_BOTTOM_MAIN',
      'GET_SPONSOR_GAMES',
      'GET_UPCOMING_TOURNAMENTS',
      'GET_SELECTED_FILTER',
    ]),
    ...mapState({
      sponsor: state => state.sponsorInfo.sponsorInfo
    }),

    isHaveTournamentGroups() {
      return this.GET_TOURNAMENT_GROUPS.length !== 0
    },

    getUpcomingTournaments() {
      try {
        return this.GET_UPCOMING_TOURNAMENTS.data
      } catch (e) {
        return []
      }
    },

    isHaveNotUpcomingTournaments() {
      return this.getUpcomingTournaments.length === 0 && this.GET_SELECTED_FILTER === 'All'
    },

    isHaveSponsorGames() {
      return this.GET_SPONSOR_GAMES.length !== 0;
    },

    isHaveTournamentGroupsAndSponsorGames() {
      return this.isHaveTournamentGroups && this.isHaveSponsorGames
    }
  },
}
</script>

<style scoped>

</style>
