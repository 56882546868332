<template>
  <div class="inner-container">
    <div class="single-slide" v-if="!loadingInProgress">
      <template v-if="Object.keys(banner).length !== 0">
        <img class="single-slide--bg" :src="this.banner.background" alt="#">
        <div class="single-slide--content">
          <div class="single-slide--label">{{ this.banner.attributes.label }}</div>
          <div class="single-slide--title">{{ this.banner.attributes.title }}</div>
          <div class="single-slide--desc">{{ this.banner.attributes.description }}</div>
          <div class="single-slide--foot">
            <div class="single-slide--discipline" v-if="this.banner.disciplines.length">
              <span>дисциплины</span>
              <div class="icons">
                <img v-for="discipline in this.banner.disciplines" :key="discipline.id"
                     :src="discipline.logo" alt="#">
              </div>
            </div>
          </div>
        </div>
        <!--      <img class="single-slide&#45;&#45;stamp" :src="require('@/img/pattern.png')" alt="#">-->
      </template>
    </div>
    <div class="single-slide" v-else>
      <skelet-rectangle :width="9999999" :height="350"/>
    </div>
  </div>
</template>

<script>
import {API} from "@/Vuex/axios-config";
import {includeFilteringByType} from "@/otherJS/include-filtering";
import {SPONSOR_ID} from "@/config";
import {mapGetters} from 'vuex';
import SkeletRectangle from "@/views/sceleton/components/rectangle";

export default {
  name: "mainPageBanner",
  components: {SkeletRectangle},
  data() {
    return {
      banner: {},
      loadingInProgress: true,
    }
  },
  computed: {
    ...mapGetters([
      'GAMES'
    ])
  },
  methods: {
    async getMainBanner() {

      this.loadingInProgress = true

      const response = await API({
        method: 'get',
        url: `/banners?filter[sponsor_id]=${SPONSOR_ID}&include=mainBannerImage,games,sponsor`
      })

      this.loadingInProgress = false

      if (response.data.data.length) {
        const banner = response.data.data[0]
        const included = response.data.included
        const backgrounds = includeFilteringByType(included, 'attachments')
        const included_games = includeFilteringByType(included, 'games')
        const games = []
        for (let el of this.GAMES) {
          for (let item of included_games) {
            if (item.id === el.id) games.push(el)
          }
        }

        banner.background = backgrounds.find(el => String(banner.relationships.mainBannerImage.data.id) === String(el.id)).attributes.url
        banner.disciplines = games
        this.banner = banner
      }

    }
  },
  async mounted() {
    await this.getMainBanner()
  }
}
</script>

<style scoped>

</style>