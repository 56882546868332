import { render, staticRenderFns } from "./upcomingTournaments.vue?vue&type=template&id=30274b58&scoped=true&"
import script from "./upcomingTournaments.vue?vue&type=script&lang=js&"
export * from "./upcomingTournaments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30274b58",
  null
  
)

export default component.exports