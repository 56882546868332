<template>
  <section class="content-section" v-if="latestNews.length">
    <div class="inner-container">
      <div class="section-head">
        <h2 class="section-title">Новости</h2>
        <router-link v-if="!isMobile" to="/news" class="ellipse-button">
          смотреть все
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5L16 12L9 19" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
      <div class="news-slider-container" v-if="!isMobile">
        <swiper ref="newsSlider" :options="swiperOption">
          <swiper-slide v-for="singleNews in this.latestNews" class="swiper-slide" :key="singleNews.id">
            <news-card with-date :single-news="singleNews"/>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
      <div class="news-slider-container__mobile" v-if="isMobile">
        <news-card with-date v-for="singleNews in this.latestNews.slice(0,3)" :key="singleNews.id"
                   :single-news="singleNews"/>
      </div>
    </div>
  </section>
</template>

<script>
import NewsCard from "@/components/News/news-card";
import {mapActions} from "vuex";

export default {
  name: "news-slider",
  components: {NewsCard},
  data() {
    return {
      dataLoaded: false,
      latestNews: [],
      isMobile: false,
      payload: {
        page_number: 1,
        page_size: 9
      },
      swiperOption: {
        init: true,
        loop: false,
        spaceBetween: 24,
        loopFillGroupWithBlank: true,
        slidesPerView: 3,
        slidesPerGroup: 3,
        speed: 500,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.newsSlider?.$swiper
    }
  },
  methods: {
    ...mapActions([
      'FETCH_LATEST_NEWS',
    ]),
    setLatestNewsToData(data) {
      return this.latestNews = data
    },
  },
  async mounted() {
    if (window.matchMedia("(max-width: 525px)").matches) this.isMobile = true
    if (window.matchMedia("(max-width: 768px)").matches) {
      this.swiper.params.slidesPerView = 2
      this.swiper.params.slidesPerGroup = 2
    }

    this.dataLoaded = false
    this.setLatestNewsToData(await this.FETCH_LATEST_NEWS(this.payload))
    this.dataLoaded = true
  }
}
</script>

<style scoped>

</style>