<template>
  <div class="inner-container">
    <div class="section-head">
      <h2 class="section-title">Рейтинг участников</h2>
    </div>

    <ul class="icon-tabs-list">
      <game-filter-for-rating v-for="game in GET_SPONSOR_GAMES" :key="game.id" :data="game"/>
    </ul>

    <div class="tabs-container">
      <div class="single-tab">
        <div class="sgl-players-list">
          <!--Тут кароч логика пизда, у нас список на 20 челибасов, где 19 пацанов
           те, которые топ19, а последний (двадцатый) - пользователь, и его место
            соответственно-->

          <media :query="{maxWidth: 768}">
            <ul>
              <rating-of-participant v-for="rating in getRatingArray" :key="rating.id" :rating="rating" />
            </ul>
          </media>

          <media :query="{minWidth: 769}">
            <ul>
              <rating-of-participant v-for="rating in getFirstHalfRatingArray" :key="rating.id" :rating="rating"/>
            </ul>
          </media>

          <media :query="{minWidth: 769}">
            <ul>
              <rating-of-participant v-for="rating in getSecondHalfRatingArray" :key="rating.id" :rating="rating"/>
            </ul>
          </media>
          <!--          <rating-of-participant v-for="(rating, index) in getRatingArray" :key="rating.id" :rating="rating" :index="index" />-->
        </div>
      </div>
    </div>
    <media :query="{maxWidth:525}">
      <div class="ellipse-button">
        смотреть все
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 5L16 12L9 19" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
        </svg>
      </div>
    </media>
  </div>
</template>

<script>
import Media from 'vue-media';
import RatingOfParticipant from "@/components/ratingOfParticipants/ratingOfParticipant/ratingOfParticipant";
import {mapActions, mapGetters} from "vuex";
import GameFilterForRating from "@/components/ratingOfParticipants/gameFilterForRating/gameFilterForRating";

export default {
  name: "ratingOfParticipants",
  components: {GameFilterForRating, RatingOfParticipant, Media},
  computed: {
    ...mapGetters([
      'GET_SPONSOR_GAMES',
      'GET_SELECTED_FILTER_RATING',
      'GET_TOURNAMENT_GROUPS',
      'GET_RATING_MAIN_TOURNAMENT_GROUPS'
    ]),

    getRatingArray() {
        // Получаю рейтинг по определенной группе турнира
        return this.GET_RATING_MAIN_TOURNAMENT_GROUPS;
    },

    getFirstHalfRatingArray() {
      const fhNumber = Number((this.getRatingArray.length / 2).toFixed());
      return this.getRatingArray.filter((_, index) => index < fhNumber)
    },

    getSecondHalfRatingArray() {
      const shNumber = Number((this.getRatingArray.length / 2).toFixed());
      return this.getRatingArray.filter((_, index) => index >= shNumber)
    },
  },

  methods: {
    ...mapActions([
        'SET_SELECTED_FILTER_RATING',
        'SET_RATING_MAIN_TOURNAMENT_GROUPS'
    ])
  },

  async mounted() {
    // Устанавливаю изначальный фильтр для рейтингов
    this.SET_SELECTED_FILTER_RATING(String(this.GET_SPONSOR_GAMES[0].id));

    // В турнирах групп хранится дисциплина. По выбранной дисциплине нахожу id группы турнира
    // По id группы турнира получаю рейтинги данной группы турнира
    const tournamentGroupId = this.GET_TOURNAMENT_GROUPS.find((item) =>
        String(item.gameInfo.id) === String(this.GET_SPONSOR_GAMES[0].id)).id

    // Получаю рейтинг по определенной группе турниров
    await this.SET_RATING_MAIN_TOURNAMENT_GROUPS(tournamentGroupId)
  },

  // watch: {
  //   async GET_SPONSOR_GAMES() {
  //
  //     if (this.GET_SPONSOR_GAMES.length !== 0) {
  //
  //       // Устанавливаю изначальный фильтр для рейтингов
  //       this.SET_SELECTED_FILTER_RATING(String(this.GET_SPONSOR_GAMES[0].id));
  //
  //       console.log(this.GET_TOURNAMENT_GROUPS)
  //
  //       // В турнирах групп хранится дисциплина. По выбранной дисциплине нахожу id группы турнира
  //       // По id группы турнира получаю рейтинги данной группы турнира
  //       const tournamentGroupId = this.GET_TOURNAMENT_GROUPS.find((item) =>
  //           String(item.gameInfo.id) === String(this.GET_SPONSOR_GAMES[0].id)).id
  //
  //       // Получаю рейтинг по определенной группе турниров
  //       await this.SET_RATING_MAIN_TOURNAMENT_GROUPS(tournamentGroupId)
  //     }
  //   }
  // }
}
</script>

<style scoped>

</style>