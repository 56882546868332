<template>
  <router-link :to="{name: 'article', params: {id: singleNews.id}}" class="news-cell">
    <img class="news-cell--bg" :src="singleNews.logo" alt="#">
    <div class="news-cell--content">
      <div class="news-cell--head">
        <div class="sgl-label sgl-label--colored">Новости</div>
        <div class="sgl-label" v-if="withDate">{{ getTimeDifference }}</div>
      </div>
      <div class="news-cell--title">{{ singleNews.attributes.title }}</div>
    </div>
  </router-link>
</template>

<script>
import moment from "moment";

export default {
  name: "news-card",
  props: {
    singleNews: Object,
    withDate: Boolean,
  },
  computed: {
    getTimeDifference() {
      moment.locale('ru')
      return moment(moment(this.singleNews.attributes.createdAt).valueOf()).fromNow();
    }
  }
}
</script>

<style scoped>

</style>