<template>
  <li @click="selectFilter" class="icon-tabs-list-item" :title="getGameName"
      :class="{'icon-tabs-list-item_selected': getIsSelected}">
    <img :src="getLogoUrl" alt="Not Found">
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "gameFilterForRating",
  props: {
    data: Object
  },

  methods: {
    ...mapActions([
      'SET_SELECTED_FILTER_RATING',
      'SET_RATING_MAIN_TOURNAMENT_GROUPS'
    ]),

    async selectFilter() {
      this.SET_SELECTED_FILTER_RATING(String(this.data.id));

      // В турнирах групп хранится дисциплина. По выбранной дисциплине нахожу id группы турнира
      // По id группы турнира получаю рейтинги данной группы турнира
      const tournamentGroupId = this.GET_TOURNAMENT_GROUPS.find((item) =>
          String(item.gameInfo.id) === String(this.data.id)).id

      // Получаю рейтинг по определенной группе турниров
      await this.SET_RATING_MAIN_TOURNAMENT_GROUPS(tournamentGroupId)
    }
  },

  computed: {
    ...mapGetters([
      'GET_SELECTED_FILTER_RATING',
      'GET_SPONSOR_GAMES',
      'GET_TOURNAMENT_GROUPS'
    ]),

    getIsSelected() {
      return this.GET_SELECTED_FILTER_RATING === String(this.data.id)
    },

    getLogoUrl() {
      return this.data.logo;
    },

    getGameName() {
      return this.data.attributes.name
    }
  },
}
</script>

<style scoped>

</style>