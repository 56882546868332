<template>
  <li @click="selectFilter" class="icon-tabs-list-item" :title="getGameName" :class="{'icon-tabs-list-item_selected': getIsSelected}">
    <img :src="getLogoUrl" alt="Not Found">
  </li>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "gameFilterComponent",
  props: {
    data: Object
  },

  methods: {
    ...mapActions([
        'SET_SELECTED_FILTER',
        'SET_UPCOMING_TOURNAMENTS'
    ]),
    async selectFilter() {
      this.SET_SELECTED_FILTER(String(this.data.id))
      await this.SET_UPCOMING_TOURNAMENTS(String(this.data.id))
    }
  },

  computed: {
    ...mapGetters([
        'GET_SELECTED_FILTER',
    ]),

    getIsSelected() {
      return this.GET_SELECTED_FILTER === String(this.data.id)
    },

    getLogoUrl() {
      return this.data.logo;
    },

    getGameName() {
      return this.data.attributes.name
    }
  },
}
</script>

<style scoped>

</style>