<template>
  <div class="inner-container">
    <div class="section-head">
      <h2 class="section-title">Ближайшие турниры</h2>
    </div>
    <ul class="icon-tabs-list">
      <!--у элементов есть класс active, для подчеркивания-->
      <li @click="setFilterAll" class="icon-tabs-list-item" :class="{'icon-tabs-list-item_selected': getFilterForAll}">Все</li>

      <game-filter-component v-for="game in this.GET_SPONSOR_GAMES" :key="game.id" :data="game" />
    </ul>

    <div class="tabs-container">
      <div class="single-tab" v-if="isHaveTournaments">
        <tournament-main-event-card v-for="tournament in getUpcomingTournaments" :key="tournament.id" :tournament="tournament" />
      </div>

      <div v-else class="haveNotTournaments">
        <p>Турниры будут, но чуть позже.</p>
        <p>Можно посмотреть <span @click="setFilterAll" class="haveNotTournaments__allTournaments">Все турниры</span> по дисциплине.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GameFilterComponent from "@/components/upcomingTournaments/gameFilterComponent/gameFilterComponent";
import TournamentMainEventCard from "@/components/TournamentMainEventCard/TournamentMainEventCard";

export default {
name: "upcomingTournaments",
  components: {TournamentMainEventCard, GameFilterComponent},
  methods: {
    ...mapActions([
        'SET_UPCOMING_TOURNAMENTS',
        'SET_SELECTED_FILTER',
        'SET_SPONSOR_GAMES',
        'GET_DISCIPLINES'
    ]),

    async setFilterAll() {
      this.SET_SELECTED_FILTER('All')
      await this.SET_UPCOMING_TOURNAMENTS('All')
    }
  },

  computed: {
    ...mapGetters([
        'GAMES',
        'GET_SPONSOR_GAMES',
        'GET_UPCOMING_TOURNAMENTS',
        'GET_SELECTED_FILTER'
    ]),

    getFilterForAll() {
      return this.GET_SELECTED_FILTER === 'All'
    },

    getUpcomingTournaments() {
      try {
        return this.GET_UPCOMING_TOURNAMENTS.data
      } catch (e) {
        return []
      }
    },

    isHaveTournaments() {
      return this.getUpcomingTournaments.length !== 0
    },
  },
}
</script>

<style scoped lang="scss">

</style>
